






































































































































































































import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { translations, eventHandler } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";
import {Select, Option} from "element-ui";
import { Order as OrderApi,  Concept as OrdersConcept } from "@/services/SOLO";
const ConceptOrdersProp = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [eventHandler, translations],
})
export default class ConceptOrders extends ConceptOrdersProp {
  translate!: Function;
  public getLocale: any;
  isApplyToAllLocation: boolean = false;
  statuses: any = [];
  confirm!: Function
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload IMG",
    },
  ];

  mounted() {
    this.getActiveOrderStatuses();
    this.data['apply-to-all-locations'] = this.isApplyToAllLocation
  }


  async getActiveOrderStatuses() {
     await OrderApi.orderStatuses()
      .then((response: any) => (this.statuses = response.data.data));
  }

  checkOpeninghours(dis: any) {
    return 'pointer-events: auto';
  }

  async onApplyToAllLocations(){
    try {
      const confirmed = await this.confirm(this.$bvModal, 'Are you sure you want to apply this to all locations?')

      if (confirmed) {
        
        const payload = {
          "delivery-charge-per-km": this.data['delivery-charge-per-km'],
          "delivery-maximum-distance": this.data['delivery-maximum-distance'],
          "default-delivery-charge": this.data['default-delivery-charge']
        }

        await OrdersConcept.applyUpdatesToLocations(payload)

        const notifyOptions: any = {
            title: 'Success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: `Applied to all locations successfully!`,
            type: 'success',
        };
        this.$notify(notifyOptions);
      }
    } catch (error) {
      console.error('An error Occured', error)
    }
  }

}
